import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';

import pc_img from './img/pexels-dzenina-lukac-930530.jpg';
import repair_img from './img/coding-1853305_1920.jpg';
import eco_img from './img/pexels-pixabay-433308.jpg';
import rivendi_img from './img/pexels-karolina-grabowska-5632382.jpg';

import logo from './img/Logo_g.png';


const Slideshow = () => {
  const slides = [
    {
      title: 'PcRicondizionati',
      subtitle: 'Store di prodotti ricondizionati',
      description: '',
      buttonText: 'Scopri di più',
      image: pc_img,
    },
    {
      title: 'Repair-Hub',
      subtitle: 'Software gestionale',
      description: 'Aumenta le produttività del tuo negozio!',
      buttonText: 'Scopri di più',
      image: repair_img,
    },
    {
      title: 'EcoSostenibile',
      subtitle: "",
      description: "Per garantire un processo ecologico nell'ambiente informatico",
      buttonText: 'Scopri di più',
      image: eco_img,
    },
    {
      title: 'Rivendi',
      subtitle: 'Piattaforma online',
      description: 'Per acquistare prodotti usati',
      buttonText: 'Scopri di più',
      image: rivendi_img,
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
    }, 7000); // Cambia slide ogni 7 secondi

    return () => clearInterval(interval);
  }, [slides.length]);

  return (
    <div className="slideshow-container">
      <div className="info_container">
          <nav className="navbar">
            <ul className="nav-menu">
              <li className="nav-item"><Link to="/">Home</Link></li>
              <li className="nav-item"><a href='#servizi'>I nostri servizi</a></li>
              <li className="nav-item"><Link to="/chi-siamo">Chi Siamo</Link></li>
            </ul>
          </nav>                
          <div className='glass-container'>
            <img src={logo} alt='' id='logo'/>
          </div>
        </div>
      <div className="slideshow-content">
        {slides.map((slide, index) => (
          <div className="slide" key={index}>
            <Fade key={index} when={currentSlide === index} duration={2000}>
              <div
                className="slide-content"
                style={{
                  backgroundImage: `url(${slide.image})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  filter: 'grayscale(80%)',
                }}
              >
                <div className='container'>
                  <Fade key={index} when={currentSlide === index} duration={2000}>
                    <div className='card'>
                      <div className='text_div'>
                        <Fade key={`content-${index}`} duration={1500} right when={currentSlide === index}>
                          <h1>{slide.title}</h1>
                          <h3 className='subtitle'>{slide.subtitle}</h3>
                          <p>{slide.description}</p>
                        </Fade>
                        {currentSlide === 0 && (
                          <a href="https://www.pc-ricondizionati.com" target="_blank" rel="noopener noreferrer">
                            <button>Scopri di più</button>
                          </a>
                        )}
                        {currentSlide === 1 && (
                          <a href="costruction.html" target="_blank" rel="noopener noreferrer">
                            <button>Scopri di più</button>
                          </a>
                        )}
                        {currentSlide === 2 && (
                          <a href="costruction.html" target="_blank" rel="noopener noreferrer">
                            <button>Scopri di più</button>
                          </a>
                        )}
                        {currentSlide === 3 && (
                          <a href="costruction.html" target="_blank" rel="noopener noreferrer">
                            <button>Scopri di più</button>
                          </a>
                        )}
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>

              
            </Fade>
          </div>
        ))}

      </div>
      <div className="pagination">
        {slides.map((_, index) => (
          <span key={index} className={currentSlide === index ? 'dot active' : 'dot'} onClick={() => setCurrentSlide(index)} />
        ))}
      </div>
      
    </div>
  );
};

export default Slideshow;

