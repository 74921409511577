//import Sliders from './slider';
import Slideshow from './header';
import React, { useEffect } from 'react';
import './home.css'
import Fade from 'react-reveal/Fade';

import eco from './img/eco.jpg';
import pc from './img/pc.jpg';
import commerce from './img/commerce.jpg';
import code from './img/code.jpg';
import LOGO3 from './img/LOGO3.png'


export default function Home() {
  
  return (
    <div>
        <Slideshow /><br></br>
        <Fade left duration={2700}>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"></link>
          <hr className='text_hr'></hr>
          <blockquote className="blockquote">
            <Fade left duration={3000}>
              <p>Noi della NCOMIT, offriamo diversi servizi, ma il nostro punto forte sono i prodotti informatici, <em> ricondizionati</em> da personale qualificato e tutto fatto in Italia. Questo perchè crediamo che il Made In Italy, sia la chiave per conoscere le esigenze dei clienti così da poterle soddisfare. </p>
            </Fade>
          </blockquote>
          <hr className='text_hr'></hr></Fade>
        <br></br>
        
        <div className="container_card" id='servizi'>
          <Fade left duration={2700}>
            <div className="card-site">
              <img src={pc} alt="Card Image" className="card-img"/>
              <div className="card-content">
                <h2>Pc-Ricondizionati</h2>
                <div style={{display:"flex"}}>
                  <p>Store di prodotti informatici, con più di 5000 prodotti tra portatili, workstation e molto altro in pronta consegna, per soddisfare le tue esigenze e quelle dei tuoi clienti.</p>
                  <img src={LOGO3} className='logo3'></img>
                </div>
                
                <a href='https://www.pc-ricondizionati.com/' target="_blank" rel="noreferrer">
                  <button className='card_button'>
                    Visita <svg style={{marginBottom:"-3px"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
                          </svg>
                  </button>
                </a>
              </div>
            </div>
          </Fade>
          <Fade left duration={3000}>
           <div className="card-site">
            <img src={code} alt="Card Image" className="card-img"/>
            <div className="card-content">
              <h2>Repair-Hub</h2>
              <p>Software gestionale realizzato interamente da noi, ideato per massimizzare l'efficienza dei negozi specializzati nell'assistenza tecnica informatica.</p>
              <a href='costruction.html' target="_blank" rel="noreferrer">
                <button className='card_button'>
                  Visita <svg style={{marginBottom:"-3px"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
                        </svg>
                </button>
              </a>
            </div>
          </div>
          </Fade>
          <Fade left duration={2700}>
            <div className="card-site">
              <img src={eco} alt="Card Image" className="card-img"/>
              <div className="card-content">
                <h2>EcoSostenibile</h2>
                <p>L'ecosotenibilità è per noi un obiettivo importante, per questa ragione portiamo avanti questo progetto: EcoSostenibile.</p>
                <a href='costruction.html' target="_blank" rel="noreferrer">
                  <button className='card_button'>
                    Visita <svg style={{marginBottom:"-3px"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
                          </svg>
                  </button>
                </a>
              </div>
            </div>
          </Fade>
          <Fade left duration={3000}>
            <div className="card-site">
              <img src={commerce} alt="Card Image" className="card-img"/>
              <div className="card-content">
                <h2>Rivendi</h2>
                <p>E' una piattaforma online che ci mette in contatto con rivenditori per l'acquisto di prodotti usati, per poiter continuare a offire una vasta gamma di prodotti.</p>
                <a href='costruction.html' target="_blank" rel="noreferrer">
                  <button className='card_button'>
                    Visita <svg style={{marginBottom:"-3px"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
                          </svg>
                  </button>
                </a>
              </div>
            </div>
          </Fade>
        </div>

    </div>
  );
}
