import './App.css';
import { BrowserRouter, Route, Routes} from 'react-router-dom';

import Home from './components/home/home';
import ChiSiamo from './components/chi_siamo';
import Storia from './components/storia';
import Mission from './components/mission';
import Footer from './components/footer'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/chi-siamo" element={<ChiSiamo />}></Route>
        </Routes>
      </BrowserRouter>
      <Footer />

    </div>
  );
}

export default App;
