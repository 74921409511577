import Navbar from "./navbar";
import Fade from 'react-reveal/Fade';
import Linea from "./linea-tempo/linea";

export default function ChiSiamo () {
    return(
        <div>
            <Navbar />
           
            
            <Fade left duration={2700}>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"></link>
          <hr className='text_hr'></hr>
          <blockquote className="blockquote">
            <Fade left duration={3000}>
              <p>La NCOMIT è un'azienda italiana leader nel settore dei prodotti informatici ricondizionati, con una storia di successo che risale al 2005. Fondata con l'obiettivo di offrire prodotti di alta qualità e servizi di assistenza informatica, l'azienda si è rapidamente affermata come punto di riferimento nel mercato italiano. Attraverso un'attenta selezione e ricondizionamento dei prodotti da parte di personale qualificato, la NCOMIT garantisce ai suoi clienti soluzioni affidabili e convenienti.</p><br></br>
              <p>L'impegno per il "Made in Italy" è uno dei principi fondamentali dell'azienda, che crede che comprendere le esigenze dei clienti sia la chiave per soddisfare le loro necessità. Questo approccio si riflette nel continuo sviluppo di nuovi prodotti e servizi, con un'attenzione particolare alla qualità e alla soddisfazione del cliente.</p>
            </Fade>
          </blockquote>
          <hr className='text_hr'></hr></Fade>
        <Fade left duration={2700}>
            <br></br>
            <h3 style={{marginLeft:"5rem"}}>La nostra storia:</h3>
            <Linea />
            <hr className='text_hr'></hr>
        </Fade>
        <br></br>
        <p style={{alignItems:"center", textAlign:"center"}}>Ringraziamo a tutti i clienti e collaboratori che ci sostengono a portare avanti un progetto eco-sostenibile </p><br/>
        </div>
    );
}