import './linea.css'
import Fade from 'react-reveal/Fade';

export default function Linea() {
    return (
        <section className="design-section">
            <div className="timeline">
                <div className="timeline-empty">
                </div>
                <div className="timeline-middle">
                    <div className="timeline-circle"></div>
                </div>
                <Fade left duration={2700}>
                  <div className="timeline-component timeline-content">
                    <h3>2005</h3>
                    <p>Assistenza Informatica</p>
                </div>  
                </Fade>
                <Fade left duration={3000}>
                <div className="timeline-component timeline-content">
                    <h3>2006</h3>
                    <p>Vendita e assemblaggio computer</p>
                </div></Fade>
                <div className="timeline-middle">
                    <div className="timeline-circle"></div>
                </div>
                <div className="timeline-empty">
                </div>
                <div className="timeline-empty">
                </div>
                <div className="timeline-middle">
                    <div className="timeline-circle"></div>
                </div>
                <Fade left duration={2700}>
                <div className="timeline-component timeline-content">
                    <h3>2010</h3>
                    <p>Primo Store a Roma</p>
                </div></Fade>
                <Fade left duration={3000}>
                <div className="timeline-component timeline-content">
                    <h3>2014</h3>
                    <p>Secondo Store a Roma </p>
                </div></Fade>
                <div className="timeline-middle">
                    <div className="timeline-circle"></div>
                </div>
                <div className="timeline-empty">
                </div>
                <div className="timeline-empty">
                </div>
                <div className="timeline-middle">
                    <div className="timeline-circle"></div>
                </div>
                <Fade left duration={2700}>
                <div className="timeline-component timeline-content">
                    <h3>2015</h3>
                    <p>Distribuzione Prodotti Ricondizionati </p>
                </div></Fade>
                <Fade left duration={3000}>
                <div className="timeline-component timeline-content">
                    <h3>2017</h3>
                    <p>Primo Magazzino all’ingrosso </p>
                </div></Fade>
                <div className="timeline-middle">
                    <div className="timeline-circle"></div>
                </div>
                <div className="timeline-empty">
                </div>
                <div className="timeline-empty">
                </div>
                <div className="timeline-middle">
                    <div className="timeline-circle"></div>
                </div>
                <Fade left duration={2700}>
                <div className="timeline-component timeline-content">
                    <h3>2018</h3>
                    <p>Nasce www.pc-ricondizionati.com con oltre €1Milione di Fatturato </p>
                </div></Fade>
                <Fade left duration={3000}>
                <div className="timeline-component timeline-content">
                    <h3>2020</h3>
                    <p>Oltre 500 Rivenditori B2B iscritti con € 4.5M di Fatturato </p>
                </div></Fade>
                <div className="timeline-middle">
                    <div className="timeline-circle"></div>
                </div>
                <div className="timeline-empty">
                </div>
                <div className="timeline-empty">
                </div>
                <div className="timeline-middle">
                    <div className="timeline-circle"></div>
                </div>
                <Fade left duration={2700}>
                <div className="timeline-component timeline-content">
                    <h3>2023</h3>
                    <p>Trasferimento alla Nuova Sede con tante nuove idee e progetti </p>
                </div></Fade>
                
                
            </div>

           
        </section>
    );
}