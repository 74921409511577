export default function Footer () {
    return(

        <footer>
            <div className="logo-container">
                <img src="logo.png" alt="Logo" className="logo" />
            </div>
            <div className="content-container">
                <div className="box">
                    <h4>I nostri Brand</h4>
                    <ul>
                        <li>PC-Ricondizionati</li>
                        <li>Repair-Hub</li>
                        <li>EcoSostenibile</li>
                        <li>Rivendi</li>
                    </ul>
                </div>
                <div className="box">
                    <h4>Contattaci</h4>
                    <ul>
                        <li>Telefono: +39 067236020</li>
                        <li>Email: info@ncomit.eu</li>
                    </ul>
                </div>
                <div className="box">
                    <h4>Aspetti Legali</h4>
                    <ul>
                        <li><a href="https://www.pc-ricondizionati.com/privacy-policy/" target="_blank" style={{textDecoration:"none", color: "inherit"}}>Policy sulla Privacy</a></li>
                        <li><a href="https://www.pc-ricondizionati.com/299-2/"  target="_blank"style={{textDecoration:"none", color: "inherit"}}>Termini e condizioni</a></li>
                    </ul>
                </div>  {
                    /*<div className="box" id="box4">
                    <form>
                        <ul>
                            <li><h4>Contattaci</h4></li>
                            <li><input id="messaggio" type="text" placeholder="Messaggio"></input></li>
                            <li><input id="email" type="mail" placeholder="Email"></input></li>
                            <li><button>Invia</button></li>
                        </ul>
                    </form>
                </div>*/ 
                }
                
            </div>
            <div className="footer-text">
                <p>NCOM SRL sede legale Via del Casale Santarelli 49 Roma - P.iva 14001151001 - Capitale sociale 100.000,00 IV</p>
            </div>
        </footer>
    );
}