import { Link } from "react-router-dom"
import logo from './home/img/Logo_g.png'

export default function Navbar () {
    /*.navbar {
    z-index: 10;
    top: 0;
    left: 0;
    padding: 10px;
    margin-top: 22rem;
    margin-left: 1rem;
  }

  Link:hover{
    color: #FFF200;
  }
  
  .nav-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    margin-left: 2rem;
  }
  
  .nav-item {
    margin-left: 10px;
    color: #ffffff;
  }
  
  .nav-item:first-child {
    margin-left: 0;
  }
  
  .nav-item a {
    text-decoration: none;
    color: #ffffff;
  }
  
  .nav-item:nth-child(even) a {
    color: #ffffff; 
  }
  
  .nav-item:not(:first-child) {
    padding-left: 10px;
    border-left: 1px solid white; /* Aggiungi una barra verticale per le voci alternative 
  }*/

    return(
    <header className="navbar2">
        <div className="navbar-container">
          <div className="navbar-brand">
            <img src={logo} alt="Logo" className="logo_navbar2"/>
          </div>
          <nav className="navbar-menu2">
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><a href="/#servizi">I nostri servizi</a></li>
              <li><Link to="/chi-siamo">Chi Siamo</Link></li>
            </ul>
          </nav>
        </div>
      </header>
    );
}